















































































import {vxm} from "@/store";
import {Component, Vue, Watch} from 'vue-property-decorator';
import {
  CaseApiFactory,
  CaseDTO,
  CaseFilterDTO,
  CaseStatus,
  CompanyDTO,
  Configuration, ErpcDTO
} from "@shared_vue/openapi/caseapi";
import 'firebase/auth';

import {ServiceBusClient, ServiceBusReceivedMessage, ServiceBusReceiver} from "@azure/service-bus";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import ErpcAddModal from "@/views/widgets/modals/ErpcAddModal.vue";
import ButtonDateInput from "@/views/viewcomponents/ButtonDateInput.vue"
import axios from 'axios';
import firebase from "firebase";
import {response} from "msw";


@Component({components: { WaitModal, ButtonDateInput, ErpcAddModal } })
export default class ErpcList extends Vue {

  private ui = vxm.ui;
  private caseStore = vxm.case;
  private caseApi = CaseApiFactory(<Configuration>{basePath:process.env.VUE_APP_CASE_API_URL},process.env.VUE_APP_CASE_API_URL)
  private showWait: boolean = false;
  private showAddModal: boolean = false;
  private companies: CompanyDTO[] = [];
  // private branches: ErpcDTO[] = [];
  private checkedRows: Array<number> = [];
  private imagePath = process.env.VUE_APP_STATIC_DIR + 'images/icons/';
  
  


  private fields = [
    {key: 'id'},
    {key: 'logo', label: 'Logo', _style: 'width:100px'},
    {key: 'name', label: 'Name', _style: 'min-width:150px;'},
    {key: 'status', _style: 'width:60px;'},
    {
      key: 'select',
      _style: 'width:1%',
      sorter: false,
      filter: false
    }
  ];
  private getImage(logo: any) {
    if (logo) {
      return this.imagePath + logo
    }
  };
  
  private async fetchErpcs(){
    this.showWait = true;
    try {

      // const branches = await this.caseApi.caseGetBranches();
      // this.branches = branches.data;
      const companies = await this.caseApi.caseGetCompanies();
      this.companies = companies.data;
    } catch (e) {
      console.log(e)
    } finally {
      this.showWait = false;
    }
  }

  private async massAction(which: string){
    this.showWait = true;
    try {
      if (this.checkedRows.length === 0) {
        alert('YOU MUST SELECT SOMETHING');
      }
      await this.caseApi.caseBulkUpdateErpcStates({
        status: which,
        caseIds: this.checkedRows
      });
      await this.fetchErpcs();
      this.checkedRows = [];
    } finally {
      this.showWait = false;
    }
  }

  private check (item:any, checked: boolean) { //the whole data row is passed in
    console.log(`${item.id} is ${checked}`)
    if (checked) {
      // Add the ID to the array if it's checked
      if (!this.checkedRows.includes(item.id)) {
        this.checkedRows.push(item.id);
      }
    } else {
      // Remove the ID from the array if it's unchecked
      this.checkedRows = this.checkedRows.filter(rowId => rowId !== item.id);
    }
  }

  private async onClickCompany(item:any, index:any, column:any, event:any){
    console.log("onclickcompany")
    console.log('column: ' + column)
    if (column==='select') { //don't navigate
      return;
    }
    let id = item.id;
    //note that the flow for "NEW" is different to the others
    this.$router.push(`/dashboard/company/${id}`)
  }

  private getColor(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return '#f02b1d';
      case 'active':
        return '#95eb34';
      default:
        '#FFF'
    }
  };
  
  private addnew(){
    this.showAddModal = true;
  }
  
  private async addCompany(company: any){
    console.log('addCompany')
    console.log(company)
    this.showWait = true;
    try {
      const companyDTO = <CompanyDTO>{
        name: company.name.toUpperCase(),
        partnerType: company.payment.toUpperCase(),
        products: company.product.map(p => p.value),
        status: 'ACTIVE',
      }
      await this.caseApi.caseUpsertCompany(companyDTO);
      await this.fetchErpcs();
    } finally {
      this.showWait = false;
    }
  }
  mounted(){
    this.fetchErpcs();
  }
}


