var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"case-table"},[_c('CContainer',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex justify-content-between  pt-3"},[_c('h5',{staticClass:"mb-0"},[_vm._v("ERPC List")]),_c('div',{staticClass:"d-flex"},[_c('CDropdown',{staticClass:"btn-card ml-2",attrs:{"shape":"square","variant":"outline","color":'dark',"toggler-text":"MASS ACTION"}},[_c('CDropdownItem',[_c('CButton',{attrs:{"block":"","variant":"outline","color":"primary"},on:{"click":function($event){return _vm.massAction('DELETED')}}},[_vm._v("DELETE")])],1),_c('CDropdownItem',[_c('CButton',{attrs:{"block":"","variant":"outline","color":"primary"},on:{"click":function($event){return _vm.massAction('ACTIVE')}}},[_vm._v("ACTIVATE")])],1),_c('CDropdownItem',[_c('CButton',{attrs:{"block":"","variant":"outline","color":"primary"},on:{"click":function($event){return _vm.massAction('DISABLED')}}},[_vm._v("DEACTIVATE")])],1),_vm._v(" Mass Action ")],1),_c('CButton',{on:{"click":_vm.addnew}},[_vm._v(" Add New ")])],1)]),_c('CCardBody',{staticClass:"pt-0 px-0"},[_c('CDataTable',{staticClass:"table-bordered text-center hidefirst",attrs:{"items":_vm.companies,"fields":_vm.fields,"column-filter":"","table-filter":"","items-per-page-select":"","items-per-page":25,"sorter":"","sorter-value":{ column: 'name', asc: true },"pagination":"","clickable-rows":"","hover":""},on:{"row-clicked":_vm.onClickCompany},scopedSlots:_vm._u([{key:"logo",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CImg',{attrs:{"src":_vm.getImage(item.logo),"width":"100px"}})],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{style:('color: #000; background-color:'+ _vm.getColor(item.status)+';')},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"select",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CInputCheckbox',{attrs:{"id":'case_row_'+item.id,"size":"sm","checked":_vm.checkedRows.includes(item.id)},on:{"update:checked":function (checked) { return _vm.check(item, checked); }}})],1)]}}])})],1)],1),_c('WaitModal',{attrs:{"title":"Loading...","showvar":_vm.showWait},on:{"update:showvar":function($event){_vm.showWait=$event}}}),_c('ErpcAddModal',{attrs:{"show":_vm.showAddModal},on:{"update:show":function($event){_vm.showAddModal=$event},"newcompany":_vm.addCompany}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }